//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Color Scheme
// Change the hex values below to alter the main color scheme.

// $primary: #00a8f6;
// $primary-2: #3f4a3c;
// $primary-3: #00a31d;

$primary: #e2c400;
$primary-2: #78c900;
$primary-3: #00a8f6;

// $primary: #d62828;
// $primary-2: #f77f00;
// $primary-3: #2d352b;

// $primary: #f77f00;
// $primary-2: #d62828;
// $primary-3: #2d352b;

// $primary: #2d352b;
// $primary-2: #d62828;
// $primary-3: #f77f00;

// $primary: #2d352b;
// $primary-2: #f77f00;
// $primary-3: #d62828;

// $primary: #d61919;
// $primary-2: #f77f00;
// $primary-3: #1c211a;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3
);

$theme-colors: (
  "primary": $primary,
  "primary-2": $primary-2,
  "primary-3": $primary-3
);
